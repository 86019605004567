<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button
        v-if="!order.moyScladId"
        size="small"
        type="danger"
        @click="createOrderMS()"
      >
        Создать в МС
      </el-button>
      <router-link
        :class="$style.routeLink"
        :to="'/addwine/orders/' + $route.params.id"
        target="_blank"
      >
        <el-button size="small" type="success"> Просмотр заказа </el-button>
      </router-link>
      <el-button size="small" type="primary" @click="submitForm('form')">
        Редактировать
      </el-button>
    </div>
    <el-form
      :class="$style.form"
      :model="order"
      ref="form"
      :rules="rules"
      label-position="right"
      label-width="200px"
    >
      <el-form-item label="Название заказа" prop="name">
        <el-input v-model="order.name" readonly></el-input>
      </el-form-item>

      <el-form-item label="Статус заказа">
        <el-input :value="order.state.name" readonly></el-input>
      </el-form-item>

      <el-form-item label="Статус оплаты">
        <el-select
          :class="$style.select"
          v-model="order.paymentStatus"
          placeholder="Выберите cтатус оплаты"
          :disabled="currentOrderPaymentStatus.isFinal"
        >
          <el-option
            v-for="item in paymentStatusOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Способ оплаты" prop="paymentType">
        <el-select
          :class="$style.select"
          v-model="order.paymentType"
          placeholder="Выберите"
          filterable
          :disabled="currentOrderPaymentStatus.isFinal"
        >
          <el-option
            v-for="item in paymentTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :disabled="
              !availablePaymentTypes.find(
                (paymentType) => paymentType.id === item.id,
              )
            "
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Тип доставки" prop="deliveryType">
        <el-select
          :class="$style.select"
          v-model="order.deliveryType"
          placeholder="Выберите тип"
          @change="changeDeliveryType"
        >
          <el-option
            v-for="item in deliveryOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
            :disabled="
              item.value === $options.DELIVERY_TYPE.DELIVERY &&
              !deliveryAddresses.length
            "
          >
          </el-option>
        </el-select>
      </el-form-item>

      <div v-if="order.deliveryType === $options.DELIVERY_TYPE.DELIVERY">
        <el-form-item label="Экспресс-доставка">
          <el-checkbox
            v-model="order.isExpressDelivery"
            @change="handleChangeIsExpressDelivery"
          />
        </el-form-item>
        <el-form-item label="Адрес доставки" prop="deliveryAddress">
          <el-select
            :class="$style.select"
            v-model="order.deliveryAddress"
            value-key="id"
            :placeholder="getDeliveryAddressString()"
            filterable
            @change="handleSelectDeliveryAddress"
          >
            <el-option
              v-for="item in deliveryAddresses"
              :key="item.id"
              :label="item.city + ', ' + item.street"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Дата доставки" prop="deliveryAt">
          <AdwCalendar
            id="orderDatePicker"
            :value="order.deliveryAt"
            :disabled-dates="disabledDates"
            minimum-view="day"
            maximum-view="day"
            placeholder="Дата доставки"
            is-hide-icon
            @selected="handleChangeDeliveryDate"
          />
        </el-form-item>

        <el-form-item label="Цена доставки">
          <el-input
            v-model.number="order.deliveryPrice"
            :readonly="isDeliveryPriceReadOnly"
          ></el-input>
        </el-form-item>
      </div>

      <el-form-item label="Скидка">
        <el-input :value="order?.discount" readonly></el-input>
      </el-form-item>
      <el-form-item v-if="promoCode?.code" label="Промокод">
        <el-input :value="promoCode.code" readonly></el-input>
      </el-form-item>
      <el-form-item label="Получатель">
        <el-input
          :value="order.client.firstName + ' ' + order.client.lastName"
          readonly
        ></el-input>
      </el-form-item>

      <el-form-item label="Телефон">
        <el-input :value="order.client.phone" readonly></el-input>
      </el-form-item>
      <el-form-item label="Почта">
        <el-input :value="order.client.email" readonly></el-input>
      </el-form-item>
      <el-form-item label="Комментарий">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="Введите комментарий"
          v-model="order.comment"
        >
        </el-input>
      </el-form-item>

      <h3>Состав заказа</h3>

      <el-form-item label="Выберите новый товар">
        <div :class="$style.search">
          <Autocomplete
            :search="search"
            placeholder="Поиск товара"
            :valueNames="['name']"
            @querySearch="querySearch"
            @selectItem="handleSelectProduct"
            @input="search = $event"
          />
        </div>
      </el-form-item>
      <el-table :data="order.products" stripe>
        <el-table-column label="Изображение">
          <template slot-scope="scope">
            <img
              v-if="scope.row.imagePath"
              width="auto"
              height="70"
              :src="$configData.s3_link + scope.row.imagePath"
              alt=""
            />
            <img
              v-else-if="scope.row.images"
              width="auto"
              height="70"
              :src="$configData.s3_link + scope.row.images[0].original"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column prop="vendorCode" label="Артикул"> </el-table-column>
        <el-table-column prop="name" label="Наименование товара">
        </el-table-column>
        <el-table-column label="Количество">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.quantity"
              controls-position="right"
              :min="scope.row.stepQuantity"
              :max="scope.row.stocks?.[0].stock"
              :step="scope.row.stepQuantity"
              @change="changeProductQuantity(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="Цена по скидке">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.discountPrice"
              controls-position="right"
              :min="1"
              :max="scope.row.maxDiscountPrice"
              @change="changeProductQuantity(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="Скидка">
          <template slot-scope="scope">
            {{ scope.row.discount ? scope.row.discount : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="Суммарная цена">
          <template slot-scope="scope">
            {{
              scope.row.quantity *
              getPrice(scope.row.discountPrice, scope.row.price)
            }}
          </template>
        </el-table-column>

        <el-table-column width="120">
          <template slot-scope="scope">
            <div :class="$style.buttons">
              <a
                :href="
                  $configData.addwine_link + '/catalogue/' + scope.row.slug
                "
                target="_blank"
              >
                <Icon name="eye" :class="$style.icon" />
              </a>
              <Icon
                name="trash"
                :class="$style.icon"
                @click="removeProduct(scope.row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>

      <h1 :class="$style.price">Сумма: {{ totalPrice.toFixed(2) }} ₽</h1>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import { debounce } from '@/helpers/debounce.js'

import { DELIVERY_TYPE_DISPLAY, DELIVERY_TYPE } from '@/constants/deliveryType'
import { ORDER_STATE_TYPES } from '@/constants/orderStatuses'
import { UNAVAILABLE_DELIVERY_DAYS_TYPES } from '@/constants/deliverySettings'
import { PRESENCES } from '@/constants/productPresences'
import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'
import { PROMOCODE_TYPE } from '@/constants/promocodeTypes'

import Autocomplete from '@/components/atoms/Autocomplete.vue'
import Icon from '@/components/atoms/Icon.vue'

export default {
  DELIVERY_TYPE,
  PROMOCODE_TYPE,
  components: {
    Autocomplete,
    Icon,
  },
  mixins: [notifications],
  data() {
    return {
      deliveryOptions: [
        { name: 'Самовывоз', value: DELIVERY_TYPE.SELFDELIVERY },
        { name: 'Доставка', value: DELIVERY_TYPE.DELIVERY },
      ],
      datePickerOptions: {
        disabledDate(date) {
          return date < new Date()
        },
      },
      search: '',
      isAvailableForDelivery: true,
      deliveryAddresses: [],
      isAvailableForExpressDelivery: false,
      deliveryPriceData: {
        simpleDeliveryPrice: 0,
        expressDeliveryPrice: 0,
      },
      paymentStatusOptions: [],
      paymentTypes: [],
      availablePaymentTypes: [],
      currentOrderPaymentStatus: '',
      promoCode: null,
      order: {
        id: '',
        name: '',
        paymentType: {},
        client: {},
        deliveryAddress: {},
        products: [],
        state: {},
        deliveryAt: new Date(),
        deliveryType: null,
      },
      addedProducts: [],
      deleteProducts: [],
      total: 0,
      deliverySettings: {
        restrictedDays: {
          events: [],
        },
        expressDeliveryTimeRange: {
          from: '',
          to: '',
        },
      },
      disabledDates: {
        dates: [],
        to: '',
        ranges: [],
      },
      dayInMs: 24 * 60 * 60 * 1000,
      rules: {
        deliveryType: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        deliveryAddress: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        deliveryAt: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        paymentType: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    productAmount() {
      return this.order.products.reduce(
        (acc, cur) =>
          this.getPrice(cur.discountPrice, cur.price) * cur.quantity + acc,
        0,
      )
    },
    isOrderContainsLargeProduct() {
      return this.order.products?.some((e) => e?.isLarge)
    },
    isDeliveryPriceReadOnly() {
      return this.isOrderContainsLargeProduct
        ? false
        : this.isAvailableForDelivery
    },
    totalPrice() {
      if (!this.order.products?.length) return 0

      const price =
        this.productAmount +
        this.order.deliveryPrice -
        (this.order.discount ?? 0)

      return price > 0 ? price : 0
    },
  },

  async created() {
    await this.getOrder()
    await Promise.all([
      this.getListPaymentTypes(),
      this.getPaymentStatusOptions(),
      this.getDeliverySettings(),
    ])
    this.getDisabledDates(true)
    await this.getAvailabilityByCoordinates()
    this.applyPromo = debounce(this.submitPromo, 500)
  },
  methods: {
    setDeliveryPrice() {
      if (this.isOrderContainsLargeProduct) return
      this.order.deliveryPrice = this.order.isExpressDelivery
        ? this.deliveryPriceData.expressDeliveryPrice ||
          this.order.deliveryPrice
        : this.deliveryPriceData.simpleDeliveryPrice || this.order.deliveryPrice
    },
    getDeliveryAddressString() {
      return this.order.deliveryAddress?.city &&
        this.order.deliveryAddress?.street
        ? this.order.deliveryAddress.city +
            ', ' +
            this.order.deliveryAddress.street
        : ''
    },
    async changeProductQuantity(product) {
      const index = this.order.products.findIndex(
        (prod) => prod.id === product.id,
      )
      if (index < 0) {
        return
      }
      this.order.products[index] = product
      if (this.promoCode.code) {
        await this.applyPromo()
      }
      await this.getAvailabilityByCoordinates()
      this.setDeliveryPrice()
    },

    async removeProduct(data) {
      const isConfirm = confirm(`Вы точно хотите удалить ${data.name} ?`)
      if (!isConfirm) return

      this.order.products = this.order.products.filter(
        (product) => product.id !== data.id,
      )

      if (
        data?.isLarge &&
        this.order.deliveryType === DELIVERY_TYPE.DELIVERY &&
        !this.isOrderContainsLargeProduct
      )
        this.showNotification(
          'В заказе больше нет крупногабаритных товаров. Стоимость доставки будет рассчитана автоматически',
          'warning',
        )

      if (
        this.promoCode.code &&
        this.promoCode?.type === PROMOCODE_TYPE.NOMINAL
      ) {
        await this.applyPromo()
      }
      await this.getAvailabilityByCoordinates()
      await this.getDisabledDates()
      this.setDeliveryPrice()
    },

    getPrice(discountPrice, price) {
      return discountPrice < price && discountPrice > 0 ? discountPrice : price
    },

    getDeliveryType(deliveryType) {
      return DELIVERY_TYPE_DISPLAY[deliveryType]
    },

    changeDeliveryType(deliveryType) {
      this.order.deliveryType = deliveryType

      if (this.order.deliveryType === DELIVERY_TYPE.SELFDELIVERY) {
        this.availablePaymentTypes = this.paymentTypes.filter(
          (paymentType) => paymentType.isAvailableForSelfDelivery,
        )
        return
      }

      if (
        this.order.deliveryType === DELIVERY_TYPE.DELIVERY &&
        this.isOrderContainsLargeProduct
      )
        this.showNotification(
          'В корзине присутствует крупногабаритный товар, укажите стоимость доставки',
          'warning',
        )

      this.getAvailabilityByCoordinates()
    },

    async getDeliveryAddresses() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } = await delivery.AccountsCore.UsersActions.getById(
        this.order.userAccountID,
      )
      loading.close()
      if (error) return

      this.deliveryAddresses = value.deliveryAddresses?.length
        ? value.deliveryAddresses
        : []
    },

    getListPaymentTypes() {
      return new Promise((resolve) => {
        return delivery.PaymentServiceCore.PaymentTypesActions.getList({
          limit: this.limit,
          page: this.page,
        }).then((response) => {
          if (response.error) {
            resolve()
            return
          }

          this.paymentTypes = response.value.data.filter(
            (paymentType) => paymentType.isActive,
          )
          resolve()
        })
      })
    },

    getPaymentStatusOptions() {
      return new Promise((resolve) => {
        return delivery.PaymentServiceCore.PaymentStatusesActions.getList().then(
          (response) => {
            if (response.error) {
              resolve()
              return
            }

            this.paymentStatusOptions = response.value.data
            resolve()
          },
        )
      })
    },

    async getOrder() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.PaymentServiceCore.GeneralOrdersActions.getById(
          this.$route.params.id,
        )

      loading.close()
      if (error) return

      if (value.deliveryAt) value.deliveryAt = new Date(value.deliveryAt)

      if (value.adPromo || value.giftPromo) {
        this.promoCode = {
          code: value?.adPromo?.code || value?.giftPromo?.code,
          type: value?.adPromo?.type || value?.giftPromo?.type,
        }
      }

      this.order = value
      this.currentOrderPaymentStatus = this.order.paymentStatus
      this.order.paymentStatus = this.order.paymentStatus.id
      this.order.paymentType = this.order.paymentType.id
      this.order.products = this.order.products.map((product) => ({
        ...product,
        id: product.productServiceId,
        maxDiscountPrice: product.discountPrice,
      }))

      if (this.order?.userAccountID) this.getDeliveryAddresses()
    },

    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.ProductsCore.CatalogsActions.getCatalog({
          limit: 50,
          search: queryString,
        })

      if (error) return

      setSearchItems(value.products.data)
    },

    async handleSelectProduct(selectedProduct) {
      if (this.order.products?.some((e) => e.id == selectedProduct.id)) {
        this.showNotification(
          `Товар: ${selectedProduct.name} уже добавлен`,
          'error',
        )
        return
      }
      if (
        selectedProduct.presence === PRESENCES.REMOTE_WAREHOUSE &&
        this.order.isExpressDelivery
      ) {
        this.showNotification(
          `Товар: ${selectedProduct.name} не доступен для экспресс доставки`,
          'error',
        )
        return
      }

      if (!(selectedProduct.stocks && selectedProduct.stocks[0].stock)) {
        this.showNotification('К сожалению, товара нет в наличии', 'error')
        return
      }

      this.order.products.push({
        ...selectedProduct,
        quantity: 1 * (selectedProduct?.stepQuantity ?? 1),
        maxDiscountPrice: selectedProduct.discountPrice.toFixed(2),
        discountPrice: selectedProduct.discountPrice.toFixed(2),
        price: selectedProduct.price.toFixed(2),
      })

      if (
        selectedProduct?.isLarge &&
        this.order.deliveryType === DELIVERY_TYPE.DELIVERY
      )
        this.showNotification(
          'В корзину был добавлен крупногабаритный товар, укажите стоимость доставки',
          'warning',
        )

      if (this.promoCode) {
        await this.applyPromo()
      }
      await this.getAvailabilityByCoordinates()
      await this.getDisabledDates()
      this.setDeliveryPrice()
    },

    createDateAsUTC(date) {
      return new Date(
        Date.UTC(
          date?.getFullYear(),
          date?.getMonth(),
          date?.getDate(),
          date?.getHours(),
          date?.getMinutes(),
          date?.getSeconds(),
        ),
      )
    },

    async createOrderMS() {
      const loading = this.$loading({
        lock: true,
      })

      const res =
        await delivery.PaymentServiceCore.GeneralOrdersActions.createOrderMS(
          this.$route.params.id,
        )
      if (!res.error) {
        await this.getOrder()
      } else {
        this.showNotification('Не удалось создать заказ в мс', 'error')
      }

      loading.close()
    },

    async submitPromo() {
      const products = this.order.products.map(({ quantity, id }) => ({
        id,
        quantity,
      }))

      const totalProductsDiscount = this.order?.products?.reduce(
        (sum, { price, discountPrice, quantity }) =>
          (sum +=
            (price === discountPrice ? price : discountPrice) *
            (quantity ?? 1)),
        0,
      )

      const data = {
        products,
        code: this.promoCode?.code,
        totalOrderPrice: this.totalPrice + totalProductsDiscount,
      }

      const { value, error } =
        await delivery.PaymentServiceCore.PromoActions.check(data)

      if (error) {
        return
      }

      this.order.products = value
    },
    async submitForm(formName) {
      if (
        this.currentOrderPaymentStatus.isFinal ||
        this.order.state.stateType == ORDER_STATE_TYPES.SUCCESSFUL ||
        this.order.state.stateType == ORDER_STATE_TYPES.UNSUCCESSFUL
      ) {
        if (this.currentOrderPaymentStatus.isFinal) {
          this.showNotification('Нельзя изменить уже оплаченный заказ', 'error')
          return
        }

        this.showNotification(
          'Нельзя изменить выполненный/отмененный заказ',
          'error',
        )

        return
      }

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          if (!this.order.products?.length) {
            this.showNotification('В заказе нет ни одного товара', 'error')
            loading.close()
            return
          }

          if (this.order.deliveryType === DELIVERY_TYPE.SELFDELIVERY) {
            this.order.deliveryAddress = null
            this.order.deliveryAddressId = null
            this.order.deliveryAt = null
            this.order.isExpressDelivery = false
          } else {
            this.order.deliveryAt = this.createDateAsUTC(this.order.deliveryAt)
          }
          const data = {
            comment: this.order.comment,
            deliveryAddress: {
              ...this.order?.deliveryAddress,
              additionalInfo:
                this.order.deliveryAddress?.additionalInfo || null,
            },
            deliveryAt: this.order.deliveryAt,
            deliveryPrice: this.order.deliveryPrice,
            deliveryType: this.order.deliveryType,
            isExpressDelivery: this.order.isExpressDelivery,
            paymentStatus: this.order.paymentStatus,
            paymentTypeId: this.order.paymentType,
            products: this.order.products,
          }

          const { error } =
            await delivery.PaymentServiceCore.GeneralOrdersActions.updateOrder(
              this.$route.params.id,
              data,
            )

          loading.close()

          if (error) {
            this.showNotification('Ошибка редактирования заказа', 'error')
            return
          }

          this.showNotification('Заказ успешно отредактирован', 'success')
          this.$router.push(ADDWINE_ADMIN_ROUTES.ORDERS.LIST)
        }
      })
    },
    async getAvailabilityByCoordinates() {
      const query = {
        geoLat: this.order.deliveryAddress?.coordinates.lat,
        geoLon: this.order.deliveryAddress?.coordinates.lng,
        price: this.productAmount,
      }
      const { value, error } =
        await delivery.PaymentServiceCore.DeliveryPricesActions.getAvailabilityByCoordinates(
          query,
        )

      if (error) return
      this.isAvailableForExpressDelivery = value.isAvailableForExpressDelivery
      this.isAvailableForDelivery = value.isAvailableForDelivery

      this.deliveryPriceData = {
        simpleDeliveryPrice: value.deliveryPrice,
        expressDeliveryPrice: value.expressDeliveryPrice,
      }

      this.availablePaymentTypes = value.paymentTypes ?? this.paymentTypes
    },
    async handleChangeIsExpressDelivery() {
      if (await this.isExpressDeliveryDisabled()) {
        if (!this.order.products?.length) {
          await this.showNotification('Экспресс доставка не доступна', 'error')
        }
        this.order.isExpressDelivery = false
        return
      }

      await this.getAvailabilityByCoordinates()
      await this.getDisabledDates()

      this.setDeliveryPrice()
    },
    getDeliverySettings() {
      return new Promise((resolve) => {
        return delivery.PaymentServiceCore.DeliverySettingsActions.get().then(
          (response) => {
            if (response.error) {
              resolve()
              return
            }

            this.disabledDates.days =
              response.value?.restrictedDays?.weekdays ?? []
            this.deliverySettings.expressDeliveryTimeRange =
              response.value.expressDeliveryTimeRange
            this.deliverySettings.restrictedDays.events =
              response.value?.restrictedDays?.events?.map((event) => ({
                from: new Date(event.from.slice(0, -1)),
                to: new Date(event.to.slice(0, -1)),
                type: event.type,
              })) ?? []

            resolve()
          },
        )
      })
    },
    async isExpressDeliveryDisabled() {
      const currentDate = new Date()

      const hasProductOnRemoteWarehouse = this.order.products.some(
        (product) => product.presence === PRESENCES.REMOTE_WAREHOUSE,
      )

      const isDeliveryDisabledRangeExist =
        this.deliverySettings.restrictedDays?.events?.some(
          (range) => currentDate >= range.from && currentDate < range.to,
        )
      const isDisabled =
        !this.isAvailableForExpressDelivery ||
        isDeliveryDisabledRangeExist ||
        hasProductOnRemoteWarehouse ||
        !this.isCurTimeInTimeRangeExpressDelivery(currentDate)
      if (!this.isAvailableForExpressDelivery && this.order.isExpressDelivery) {
        await this.showNotification(
          'в заказе есть товар(ы) недоступные для express доставки',
          'error',
        )
      } else if (isDeliveryDisabledRangeExist && this.order.isExpressDelivery) {
        await this.showNotification(
          'невозможно оформить заказ, так как текущая дата попадает в диапазон недоступных для express доставки дат',
          'error',
        )
      } else if (hasProductOnRemoteWarehouse && this.order.isExpressDelivery) {
        await this.showNotification(
          'в заказе есть товар(ы) расположенные на удаленном складе',
          'error',
        )
      } else if (
        !this.isCurTimeInTimeRangeExpressDelivery(currentDate) &&
        this.order.isExpressDelivery
      ) {
        await this.showNotification(
          'невозможно оформить express заказ, так как текущее время не попадает в диапазон доступный для express доставки',
          'error',
        )
      }

      if (isDisabled && this.order.isExpressDelivery) {
        this.order.isExpressDelivery = false
        await this.showNotification(
          'невозможно оформить express заказ на эту дату',
          'error',
        )
      }

      return isDisabled
    },
    getDisabledDates(isFirstLoad = false) {
      this.disabledDates.ranges =
        (this.order.isExpressDelivery
          ? this.deliverySettings?.restrictedDays?.events
          : this.deliverySettings?.restrictedDays?.events?.filter(
              (event) =>
                event.type ===
                UNAVAILABLE_DELIVERY_DAYS_TYPES.DELIVERY_RESTRICTED,
            )) ?? []
      this.disabledDates.dates = []

      this.disabledDates.to = new Date(Date.now() - this.dayInMs)

      if (this.order.isExpressDelivery) {
        return this.getDisabledDatesForExpressDelivery(isFirstLoad)
      }
      const hasProductOnRemoteWarehouse = !!this.order.products.find(
        (product) => product.presence === PRESENCES.REMOTE_WAREHOUSE,
      )

      if (!hasProductOnRemoteWarehouse) {
        this.getDisabledDatesForDelivery(isFirstLoad)
        return
      }

      return this.getDisabledDatesForRemoteWarehouse(isFirstLoad)
    },
    handleChangeDeliveryDate(date) {
      this.order.deliveryAt = date
      this.order.isExpressDelivery =
        new Date(date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
    },
    getDisabledDatesForDelivery(isFirstLoad) {
      if (!isFirstLoad) {
        const deliveryDays = 1

        const estimatedDeliveryDate =
          this.getEstimatedDeliveryDate(deliveryDays)

        this.setDeliveryDate(estimatedDeliveryDate)
      }
    },
    getDisabledDatesForExpressDelivery(isFirstLoad) {
      const currentDate = new Date()

      if (!this.isCurTimeInTimeRangeExpressDelivery(currentDate)) {
        this.disabledDates.dates.push(currentDate)

        if (!isFirstLoad) {
          const deliveryDays = 1

          const estimatedDeliveryDate =
            this.getEstimatedDeliveryDate(deliveryDays)

          this.setDeliveryDate(estimatedDeliveryDate)
        }
        return
      }
      this.setDeliveryDate(currentDate)
    },
    getDisabledDatesForRemoteWarehouse(isFirstLoad) {
      const deliveryDays = 3

      const estimatedDeliveryDate = this.getEstimatedDeliveryDate(deliveryDays)

      const disableToDate = new Date(
        estimatedDeliveryDate.getTime() - this.dayInMs,
      )
      this.disabledDates.to = disableToDate
      if (!isFirstLoad) {
        this.setDeliveryDate(estimatedDeliveryDate)
      }
    },
    getEstimatedDeliveryDate(deliveryDays) {
      return new Date(Date.now() + deliveryDays * this.dayInMs)
    },
    setDeliveryDate(date) {
      const currentRange = this.disabledDates.ranges.find(
        (range) => date >= range.from && date < range.to,
      )

      if (currentRange) {
        return this.setDeliveryDate(currentRange.to)
      }

      if (!this.disabledDates.days?.includes(date.getDay())) {
        this.order.deliveryAt = new Date(
          new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 1),
        )
        return
      }

      const newDeliveryDate = new Date(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          0,
          1,
        ).getTime() + this.dayInMs,
      )

      return this.setDeliveryDate(newDeliveryDate)
    },
    async handleSelectDeliveryAddress() {
      await this.getAvailabilityByCoordinates()
      if (!this.isAvailableForDelivery || this.isOrderContainsLargeProduct) {
        this.order.deliveryPrice = 0
      }
      if (this.isOrderContainsLargeProduct) {
        this.showNotification(
          'В корзине присутствует крупногабаритный товар, укажите стоимость доставки',
          'warning',
        )
        return
      }

      if (!this.isAvailableForDelivery) {
        this.showNotification(
          'К сожалению, стоимость доставки в данную локацию не указана, но вы можете указать цену доставки снизу',
          'warning',
        )
        return
      }
      await this.isExpressDeliveryDisabled()
      await this.getDisabledDates()

      this.setDeliveryPrice()
    },
    isCurTimeInTimeRangeExpressDelivery(currentDate) {
      const expressDeliveryStart =
        this.deliverySettings.expressDeliveryTimeRange.from.split(':')

      const expressDeliveryEnd =
        this.deliverySettings.expressDeliveryTimeRange.to.split(':')
      const expressDeliveryStartDate = new Date()
      expressDeliveryStartDate.setHours(
        expressDeliveryStart[0],
        expressDeliveryStart[1],
        0,
        0,
      )

      const expressDeliveryEndDate = new Date()
      expressDeliveryEndDate.setHours(
        expressDeliveryEnd[0],
        expressDeliveryEnd[1],
        0,
        0,
      )
      return (
        currentDate > expressDeliveryStartDate &&
        currentDate < expressDeliveryEndDate
      )
    },
  },
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
  }

  .select {
    width: 100%;
  }

  .price {
    text-align: right;
    padding: 0.5rem 0 0 0;
    margin-bottom: 0;
  }

  .routeLink {
    margin: 0 0.5rem;
  }

  .search {
    width: 90%;

    input {
      width: 100%;
    }
  }
  .form {
    & > div > label {
      text-align: left;
    }
  }

  .buttons {
    display: flex;
    .icon {
      fill: $light-gray;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      margin-right: 1rem;
    }
  }
}
</style>
